import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GetProps, GetRef, message, Upload } from 'antd'

import { apiUpload } from './apis'

/** 附件上传请求入参 */
export interface ICusUploadReq {
  /** 模块类型（主目录）：1-本地商品模块;2-包材模块;3-提货模块;4-账户充值模块; */
  moduleType: number
  /** 子目录，默认为当前 userId */
  userId?: string
}

/** 附件上传响应 */
export interface ICusUploadRes {
  /** 对象在oss的路径 */
  objectName: string
  /** 文件名称 */
  originalFilenam: string
  /** 文件对象完整url */
  url: string
}

/** CusUpload 组件 Props */
export type TCusUploadProps = Omit<
  GetProps<typeof Upload> & {
    /** 请求入参 */
    reqData: ICusUploadReq
  },
  'customRequest' | 'action' | 'method' | 'headers' | 'data' | 'withCredentials' | 'directory'
>

/** antd Upload 组件封装 */
export const CusUpload = Object.assign(
  forwardRef<GetRef<typeof Upload>, TCusUploadProps>(function CusUpload({ reqData, beforeUpload, ...rest }, ref) {
    const { t } = useTranslation()
    return (
      <Upload
        {...rest}
        ref={ref}
        beforeUpload={(...args) => {
          for (const { name } of args[1]) {
            if (name.includes('?') || name.includes('#')) {
              message.error(`${t('6-comp.wen-jian-ming-bu-neng-bao-han')}?#`)
              return Upload.LIST_IGNORE
            }
          }
          return beforeUpload?.(...args)
        }}
        customRequest={async options => {
          const { onProgress, onError, onSuccess, file } = options
          try {
            const res = await apiUpload(
              { ...reqData, file },
              {
                onUploadProgress: ({ loaded, total }) => {
                  if (!total) return
                  const percent = Math.round((loaded * 100) / total)
                  onProgress?.({ percent })
                },
              },
            )
            onSuccess?.(res.$data)
          } catch (err) {
            onError?.(err instanceof Error ? err : new Error())
            throw err
          }
        }}
      />
    )
  }),
  {
    /** 在 beforeUpload 中返回该值则停止上传，且列表中不展示 */
    LIST_IGNORE: Upload.LIST_IGNORE,
  },
)
