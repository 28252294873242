import { useMemoizedFn } from 'ahooks'
import { Upload, UploadFile } from 'antd'

import { fileValidate } from '@/utils/utils'
import { CusUpload, TCusUploadProps } from './CusUpload'

/** 附件上传（当 maxCount 设为 1 时请传入泛型 false） */
export const FormItemUpload = Object.assign(
  <T extends boolean = true>(
    props: Omit<TCusUploadProps, 'defaultFileList' | 'fileList' | 'onChange'> & {
      initialNameMap?: { [url: string]: string | null | undefined } | null | undefined
      /**
       * 缩略图按长边缩放（仅图片有效；不要随意变更缩放值，尽可能保持稳定）
       * @default 100
       */
      ossResizeL?: number
      value?: T extends true ? string[] : string
      onChange?: (
        value: T extends true ? string[] : string | undefined,
        name: T extends true ? string[] : string | undefined,
      ) => void
    },
  ) => {
    const { initialNameMap, ossResizeL, value, onChange, ...rest } = props

    const valueList = [value].flat(1).filter(Boolean) as string[]
    let defaultFileList: UploadFile[] = []
    if (valueList.length) {
      defaultFileList = valueList.map((v, i) => {
        let thumbUrl
        if (v.includes('.aliyuncs.com/') && !/\?|#/.test(v) && fileValidate.isImgUrl(v)) {
          thumbUrl = `${v}?x-oss-process=image/resize,l_${ossResizeL || 100}`
        }
        return {
          uid: `${i}::${v}`,
          name: initialNameMap?.[v] || v,
          url: v,
          status: 'done',
          thumbUrl,
        }
      })
    }

    const handleChange = useMemoizedFn<NonNullable<TCusUploadProps['onChange']>>(({ fileList }) => {
      if (rest.maxCount === 1) {
        const file = fileList[0] as UploadFile | undefined
        const url = file?.response?.url || file?.url
        if (url !== valueList[0]) {
          const name = file?.name
          onChange?.(url as any, name as any)
        }
      } else {
        const doneFileList = fileList.filter(f => f.status === 'done')
        const urls = doneFileList.map(f => f.response?.url || f.url || '')
        if (`${urls}` !== `${valueList}`) {
          const names = doneFileList.map(f => f.name)
          onChange?.(urls as any, names as any)
        }
      }
    })

    return <CusUpload {...rest} defaultFileList={defaultFileList} fileList={undefined} onChange={handleChange} />
  },
  {
    /** 在 beforeUpload 中返回该值则停止上传，且列表中不展示 */
    LIST_IGNORE: Upload.LIST_IGNORE,
  },
)
