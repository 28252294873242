import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { getGlobalState } from '@/globalStore/globalStore'
import { ICusUploadReq, ICusUploadRes } from './CusUpload'
import './__mock__'

/** 附件上传 */
export const apiUpload = (
  reqData: ICusUploadReq & {
    /** 附件 */
    file: string | Blob | File
  },
  opts?: Pick<AxiosRequestConfig, 'onUploadProgress'>,
): Promise<
  AxiosResponse<{
    code: string
    data: ICusUploadRes
  }>
> => {
  const formData = new FormData()

  formData.append('file', reqData.file)
  formData.append('moduleType', `${reqData.moduleType}`)
  formData.append('userId', reqData.userId || getGlobalState().userInfo?.userId || 'unknown')

  return axios.post(`/linkhub_oms/oss/upload`, formData, opts).then(res => {
    if (!res.$data) {
      throw new Error()
    }
    return res
  })
}
